import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import WalletManager from './components/WalletManager';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <WalletManager />
    </ChakraProvider>
  );
}

export default App;