import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Heading,
  Textarea,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  HStack,
  Text,
  Select,
  Input,
  Checkbox,
  InputGroup,
  InputRightElement,
  IconButton,
  useColorMode,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Spinner,
  Icon,
  Switch,
} from '@chakra-ui/react';
import {
  RepeatIcon,
  SunIcon,
  MoonIcon,
  DownloadIcon,
  DeleteIcon,
  WarningIcon,
} from '@chakra-ui/icons';
import Login from './Login';
import { supabase } from './supabaseClient';


function WalletManager() {
  const [wallets, setWallets] = useState([]);
  const [tokenMintAddress, setTokenMintAddress] = useState(localStorage.getItem('tokenMintAddress') || '');
  const [privateKeys, setPrivateKeys] = useState('');
  const [action, setAction] = useState('buy');
  const [amount, setAmount] = useState('');
  const [individualAmounts, setIndividualAmounts] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [isUpdatingBalances, setIsUpdatingBalances] = useState(false);
  const [isTransacting, setIsTransacting] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState({});
  const [sleepTime, setSleepTime] = useState(1);
  const [bumperPrivateKey, setBumperPrivateKey] = useState('');
  const [isBumperRunning, setIsBumperRunning] = useState(false);
  const [bumperTaskId, setBumperTaskId] = useState(localStorage.getItem('bumperTaskId') || '');

  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue('gray.50', 'gray.800');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isBuyAction, setIsBuyAction] = useState(true);



  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN' && session) {
        setIsLoggedIn(true);
        setUsername(session.user.email);
        fetchWallets();
      } else if (event === 'SIGNED_OUT') {
        setIsLoggedIn(false);
        setWallets([]);
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const fetchWallets = async () => {
    try {
      const { data, error } = await supabase
        .from('wallets')
        .select('*');
      if (error) throw error;
      setWallets((prevWallets) => {
        return data.map((newWallet) => ({
          ...newWallet,
          isSelected:
            prevWallets.find((w) => w.private_key === newWallet.private_key)
              ?.isSelected || false,
        }));
      });
    } catch (error) {
      console.error('Error fetching wallets:', error);
    }
  };

  const updateBalances = async () => {
    setIsUpdatingBalances(true);
    try {
      const updatedWallets = await Promise.all(wallets.map(async (wallet) => {
        const response = await fetch('https://hellodota.pro/api/check_balance', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            private_key: wallet.private_key,
            mint_str: tokenMintAddress,
          }),
        });
        if (!response.ok) throw new Error('Failed to fetch balance');
        const { solana_balance, token_balance } = await response.json();
        return { ...wallet, balance: solana_balance, token_balance };
      }));

      // Обновляем состояние React
      setWallets(updatedWallets);

      // Обновляем данные в Supabase
      if (updatedWallets.length > 0) {
        const { error } = await supabase
          .from('wallets')
          .upsert(
            updatedWallets.map(wallet => ({
              id: wallet.id,
              private_key: wallet.private_key,
              public_key: wallet.public_key,
              balance: wallet.balance,
              token_balance: wallet.token_balance,
              user_id: wallet.user_id
            })),
            { onConflict: 'private_key' }
          );

        if (error) throw error;

        toast({
          title: "Balances Updated",
          description: "Wallet balances have been successfully updated in the database.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.log("No wallets to update");
      }
    } catch (error) {
      console.error('Error updating balances:', error);
      toast({
        title: "Update Failed",
        description: "Failed to update wallet balances. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsUpdatingBalances(false);
    }
  };

  const addWallets = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');
  
      const keys = privateKeys.split('\n').filter(line => line.trim() !== '');
      const walletsToAdd = keys.map(key => {
        const [public_key, private_key] = key.split(':').map(k => k.trim());
        return {
          user_id: user.id,
          private_key, // Здесь нужно реализовать шифрование
          public_key,
          balance: 0 // Начальный баланс
        };
      });
  
      const { data, error } = await supabase
        .from('wallets')
        .insert(walletsToAdd)
        .select();
  
      if (error) throw error;
      console.log('Wallets added:', data);
      setPrivateKeys(''); // Очищаем textarea после успешного добавления
      fetchWallets(); // Обновляем список кошельков
    } catch (error) {
      console.error('Error adding wallets:', error);
      // Показать сообщение об ошибке пользователю
    }
  };

  const deleteWallets = async () => {
    const selectedPrivateKeys = wallets
      .filter((wallet) => wallet.isSelected)
      .map((wallet) => wallet.private_key);
    if (selectedPrivateKeys.length === 0) {
      toast({
        title: 'No Wallets Selected',
        description: 'Please select wallets to delete.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsDeleting(true);
    try {
      const { data, error } = await supabase
        .from('wallets')
        .delete()
        .in('private_key', selectedPrivateKeys);
      if (error) throw error;
      fetchWallets();
      toast({
        title: 'Delete',
        description: 'Wallet(s) deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting wallets:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete wallets',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleTransaction = async () => {
    setIsTransacting(true);
    setTransactionStatus({});
    try {
      const selectedWallets = wallets.filter(wallet => wallet.isSelected);
      if (selectedWallets.length === 0) {
        throw new Error('No wallets selected');
      }
  
      const results = await Promise.all(selectedWallets.map(async (wallet) => {
        const requestBody = {
          private_key: wallet.private_key,
          mint_str: tokenMintAddress,
          action: action,
          sol_in: action === 'buy' ? parseFloat(individualAmounts[wallet.private_key] || '0') : undefined,
          token_balance: action === 'sell' ? individualAmounts[wallet.private_key] || null : undefined,
        };
  
        const response = await fetch('https://hellodota.pro/api/transaction', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          throw new Error(`Failed to execute transaction for wallet ${wallet.private_key}`);
        }
  
        return await response.json();
      }));
      
      // Update transaction status for each wallet
      const newStatus = {};
      results.forEach(result => {
        newStatus[result.private_key] = result.success;
      });
      setTransactionStatus(newStatus);
  
      // Check if all transactions were successful
      const allSuccessful = results.every(result => result.success);
      
      if (allSuccessful) {
        toast({
          title: "Transaction Successful",
          description: "All transactions were executed successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Transaction Partially Successful",
          description: "Some transactions failed. Check individual wallet statuses for details.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      }
  
      // Optionally update balances after transaction
      // await updateBalances();
  
    } catch (error) {
      console.error('Error executing transaction:', error);
      toast({
        title: "Transaction Failed",
        description: error.message || "Failed to execute transaction. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsTransacting(false);
    }
  };

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    setWallets(wallets.map(wallet => ({ ...wallet, isSelected: event.target.checked })));
  };

  const handleCheckboxChange = (wallet) => {
    setWallets(wallets.map(w => 
      w.private_key === wallet.private_key ? { ...w, isSelected: !w.isSelected } : w
    ));
  };

  const handleActionChange = (isChecked) => {
    setIsBuyAction(isChecked);
    setAction(isChecked ? 'buy' : 'sell');
  };

  const exportWallets = () => {
    const walletsData = wallets.map(wallet => `${wallet.public_key}:${wallet.private_key}`).join('\n');
    const blob = new Blob([walletsData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'wallets.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: "Copied",
        description: "Copied to clipboard",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    });
  };

  const shortenKey = (key) => {
    return `${key.slice(0, 4)}...${key.slice(-4)}`;
  };

  const formatBalance = (balance) => {
    return balance !== undefined ? parseFloat(balance).toFixed(2) : '0.00';
  };

  const randomizeAmounts = () => {
    const newAmounts = {};
    wallets.forEach(wallet => {
      if (wallet.isSelected) {
        const balance = parseFloat(wallet.balance);
        if (balance > 0.02) {
          const minAmount = balance * 0.95; // 5% от баланса
          const maxAmount = balance * 0.80; // 20% от баланса
          const maxPossibleAmount = balance - 0.02; // Максимально возможная сумма, оставляющая 0.02 SOL
  
          let randomAmount = Math.random() * (maxAmount - minAmount) + minAmount;
          randomAmount = Math.min(randomAmount, maxPossibleAmount);
  
          if (randomAmount > 0) {
            newAmounts[wallet.private_key] = randomAmount.toFixed(1);
          }
        }
      }
    });
    setIndividualAmounts(newAmounts);
  };

  const filteredWallets = wallets.filter(wallet =>
    wallet.public_key.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setIsLoggedIn(false);
      setWallets([]);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleTokenMintAddressChange = (e) => {
    const newAddress = e.target.value;
    setTokenMintAddress(newAddress);
    localStorage.setItem('tokenMintAddress', newAddress);
  };

  const startBumper = async () => {
    try {
      const response = await fetch('https://hellodota.pro/api/start_task', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          private_key: bumperPrivateKey,
          sleep_time: sleepTime,
          token_address: tokenMintAddress,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to start bumper');
      }

      const data = await response.json();
      setBumperTaskId(data.task_id);
      localStorage.setItem('bumperTaskId', data.task_id);
      setIsBumperRunning(true);
      toast({
        title: "Bumper Started",
        description: "Bumper task has been started successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error starting bumper:', error);
      toast({
        title: "Error",
        description: "Failed to start bumper. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const stopBumper = async () => {
    try {
      const response = await fetch('https://hellodota.pro/api/stop_task', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          task_id: bumperTaskId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to stop bumper');
      }

      const data = await response.json();
      if (data.success) {
        setIsBumperRunning(false);
        localStorage.removeItem('bumperTaskId');
        toast({
          title: "Bumper Stopped",
          description: "Bumper task has been stopped successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('Failed to stop bumper');
      }
    } catch (error) {
      console.error('Error stopping bumper:', error);
      toast({
        title: "Error",
        description: "Failed to stop bumper. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!isLoggedIn) {
    return <Login onLogin={() => setIsLoggedIn(true)} />;
  }

  return (
    <Box p={5} bg={bg} minHeight="100vh" display="flex" justifyContent="center">
      <VStack spacing={5} align="stretch" width={{ base: '100%', md: '80%', lg: '55%' }}>
        <HStack justifyContent="space-between" flexWrap="wrap">
          <Heading>PF master</Heading>
          <HStack>
            <Text style={{fontWeight: 'bold'}}>{username}</Text>
            <Button onClick={handleLogout}>Logout</Button>
            <IconButton
              icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              onClick={toggleColorMode}
              aria-label="Toggle color mode"
            />
          </HStack>
        </HStack>

        <Box>
          <FormControl>
            <FormLabel fontWeight="bold">Add Wallets</FormLabel>
            <Textarea
              placeholder="Enter wallets in format: public_key:private_key each on new line"
              value={privateKeys}
              onChange={(e) => setPrivateKeys(e.target.value)}
              marginBottom={2}
            />
            <Button colorScheme="blue" onClick={addWallets}>
              Add Wallets
            </Button>
          </FormControl>
        </Box>

        <Box>
          <FormControl>
            <FormLabel fontWeight="bold">Token CA</FormLabel>
            <InputGroup>
              <Input
                placeholder="Enter token mint address"
                value={tokenMintAddress}
                onChange={handleTokenMintAddressChange}
                marginBottom={2}
              />
              <InputRightElement width={{ base: '8rem', md: '13rem' }}>
                <Button
                  h="2rem"
                  width={{ base: '6rem', md: '9rem' }}
                  size="md"
                  onClick={updateBalances}
                  isLoading={isUpdatingBalances}
                >
                  Update Balances
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Box>

        <Box border="1px" borderColor="gray.600" borderRadius="md" p={4} mb={4}>
          <HStack justifyContent="space-between" flexWrap="wrap">
            <Text fontWeight="bold">
              Total SOL Balance: {isUpdatingBalances ? <Spinner size="sm" /> : wallets.reduce((sum, wallet) => sum + wallet.balance, 0).toFixed(2)}
            </Text>
            <Text fontWeight="bold">
              Total Token Balance: {isUpdatingBalances ? <Spinner size="sm" /> : wallets.reduce((sum, wallet) => sum + (wallet.token_balance || 0), 0).toFixed(2)}
            </Text>
            <Text fontWeight="bold">Total Wallets: {wallets.length}</Text>
          </HStack>
        </Box>

        <Box border="1px" borderColor="gray.600" borderRadius="md" p={4} mb={4}>
          <HStack justifyContent="space-between" alignItems="flex-start">
            {/* Левая часть для транзакций */}
            <VStack align="stretch" width="48%">
              <FormControl display="flex" alignItems="center">
                <FormLabel fontWeight="bold" htmlFor="action-switch" mb="0">
                  Action
                </FormLabel>
                <HStack>
                  <Text color={!isBuyAction ? 'gray.500' : 'green.500'} fontWeight={!isBuyAction ? 'normal' : 'bold'}>Buy</Text>
                  <Switch
                    id="action-switch"
                    size="lg"
                    colorScheme="red"
                    isChecked={!isBuyAction}
                    onChange={(e) => handleActionChange(!e.target.checked)}
                  />
                  <Text color={isBuyAction ? 'gray.500' : 'red.500'} fontWeight={isBuyAction ? 'normal' : 'bold'}>Sell</Text>
                </HStack>
              </FormControl>
              
              <Button 
                colorScheme="teal" 
                onClick={handleTransaction} 
                isLoading={isTransacting}
                loadingText="Executing"
              >
                Execute Transaction
              </Button>
            </VStack>

            {/* Правая часть для бампера */}
            <VStack align="stretch" width="48%">
              <FormControl>
                <FormLabel>Bumper Private Key</FormLabel>
                <Input
                  type="password"
                  value={bumperPrivateKey}
                  onChange={(e) => setBumperPrivateKey(e.target.value)}
                  placeholder="Enter private key for bumper"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Sleep Time (1-10 seconds)</FormLabel>
                <Input
                  type="number"
                  min={1}
                  max={10}
                  value={sleepTime}
                  onChange={(e) => setSleepTime(Math.min(10, Math.max(1, parseInt(e.target.value))))}
                />
              </FormControl>
              <Button
                colorScheme={isBumperRunning ? "red" : "green"}
                onClick={isBumperRunning ? stopBumper : startBumper}
              >
                {isBumperRunning ? "Stop Bumper" : "Start Bumper"}
              </Button>
            </VStack>
          </HStack>
        </Box>
        <HStack justifyContent="space-between" flexWrap="wrap">
          <Button
            leftIcon={<DownloadIcon />}
            onClick={exportWallets}
            colorScheme="blue"
          >
            Export Wallets
          </Button>
          <Button
            leftIcon={<DeleteIcon />}
            colorScheme="red"
            onClick={onOpen}
            isLoading={isDeleting}
          >
            Delete Selected Wallets
          </Button>
        </HStack>
        <Box>
          <FormControl>
            <Input
              placeholder="Search by public key"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </FormControl>
        </Box>
        <Table variant="simple" colorScheme="teal" size="sm">
          <Thead>
            <Tr>
              <Th>
                <Checkbox isChecked={selectAll} onChange={handleSelectAll} />
              </Th>
              <Th>Private Key</Th>
              <Th>Public Key</Th>
              <Th>SOL Balance</Th>
              <Th>Token Balance</Th>
              <Th>
                Amount
                <IconButton
                  aria-label="Randomize amounts"
                  icon={<RepeatIcon />}
                  size="xs"
                  onClick={randomizeAmounts}
                  marginLeft={2}
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredWallets.map((wallet, index) => (
              <Tr key={index}>
                <Td>
                  <Checkbox
                    isChecked={wallet.isSelected}
                    onChange={() => handleCheckboxChange(wallet)}
                  />
                </Td>
                <Td
                  onClick={() => copyToClipboard(wallet.private_key)}
                  style={{ cursor: 'pointer' }}
                >
                  {shortenKey(wallet.private_key)}
                </Td>
                <Td
                  onClick={() => copyToClipboard(wallet.public_key)}
                  style={{ cursor: 'pointer' }}
                >
                  {shortenKey(wallet.public_key)}
                </Td>
                <Td>
                  {isUpdatingBalances || (isTransacting && wallet.isSelected) ? (
                    <Spinner size="sm" />
                  ) : (
                    wallet.balance.toFixed(2)
                  )}
                  {transactionStatus[wallet.private_key] === false && (
                    <Icon as={WarningIcon} color="red.500" ml={2} />
                  )}
                </Td>
                <Td>
                  {isUpdatingBalances || (isTransacting && wallet.isSelected) ? (
                    <Spinner size="sm" />
                  ) : (
                    formatBalance(wallet.token_balance)
                  )}
                  {transactionStatus[wallet.private_key] === false && (
                    <Icon as={WarningIcon} color="red.500" ml={2} />
                  )}
                </Td>
                <Td>
                  <Input
                    placeholder="Amount"
                    value={individualAmounts[wallet.private_key] || ''}
                    onChange={(e) =>
                      setIndividualAmounts({
                        ...individualAmounts,
                        [wallet.private_key]: e.target.value,
                      })
                    }
                    marginBottom={2}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Deletion</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to delete the selected wallets? This action
              cannot be undone.
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  deleteWallets();
                  onClose();
                }}
                ml={3}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </VStack>
    </Box>
  );
}

export default WalletManager;